import {List, Record} from "immutable";
import WorkflowStep from "./WorkflowStep";
import SegmentLock from "./SegmentLock";
import ErrorModel from "./ErrorModel";
import {Editor} from "slate";
import {createSlate} from "../utils/slate/SlateUtils";

interface ITranslation {
    target: string,
    languageCode: string,
    workflowStep: WorkflowStep,
    previousWorkflowStep: WorkflowStep,
    nextWorkflowStep: WorkflowStep,
    markupErrors: List<String>,
    markupErrorModels: List<ErrorModel>,
    tryToApprove: boolean,
    editor: Editor,
    lock: SegmentLock | null
}

const TranslationRecord = Record<ITranslation>({
    target: '',
    languageCode: '',
    workflowStep: new WorkflowStep(),
    previousWorkflowStep: new WorkflowStep(),
    nextWorkflowStep: new WorkflowStep(),
    markupErrors: List(),
    markupErrorModels: List(),
    tryToApprove: false,
    editor: createSlate(),
    lock: null
});

export class Translation extends TranslationRecord {
    public static UnavailableTranslation = new Translation({});

    get isLocked() {
        return this.get("lock") != null;
    }

    public withTryToApprove(tryToApprove: boolean) {
        return this.set("tryToApprove", tryToApprove);
    }

    public withMarkupErrors(markupErrors: List<String>) {
        return this.set("markupErrors", markupErrors);
    }

    public withMarkupErrorModels(markupErrorModels: List<ErrorModel>) {
        return this.set("markupErrorModels", markupErrorModels);
    }

    public withPreviousWorkflowStep() {
        return this
            .set("previousWorkflowStep", new WorkflowStep())
            .set("workflowStep", this.previousWorkflowStep)
            .set("nextWorkflowStep", this.workflowStep)
    }

    updateData(translation: Translation) {
        return this
            .withTryToApprove(translation.tryToApprove)
            .withMarkupErrors(translation.markupErrors)
            .withMarkupErrorModels(translation.markupErrorModels)
            .set("target", translation.target)
            .set("workflowStep", translation.workflowStep)
            .set("previousWorkflowStep", translation.previousWorkflowStep)
            .set("nextWorkflowStep", translation.nextWorkflowStep);
    }
}

export default Translation;
