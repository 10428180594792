import {Bold} from "../../../../globals/CommonComponents";
import React, {useEffect, useState} from "react";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import Typography from "@mui/material/Typography";
import {Box, Container, Divider, Grid, Paper, Stack} from "@mui/material";
import GlossaryPairSearchResult from "../../../../model/GlossaryPairSearchResult";
import TranslationMemorySearchResult from "../../../../model/TranslationMemorySearchResult";
import sanitizeHtml from 'sanitize-html';
import {styled} from "@mui/material/styles";

const InfoContainer = styled(Container)({
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%'
});

export default function CatInfoView() {

    const segmentState = segmentEditorStore.getState();
    const [glossaryPair, setGlossaryPair] = useState(segmentState.selectedGlossaryPair);
    const [tmSuggest, setTMSuggest] = useState(segmentState.tmSuggest);

    useEffect(() => {
        const segmentEditorListener = segmentEditorStore.addListener(() => {
            const state = segmentEditorStore.getState();
            setGlossaryPair(state.selectedGlossaryPair);
            setTMSuggest(state.tmSuggest);
        });

        return () => segmentEditorListener.remove();
    });

    return (
        <InfoContainer>
            {drawGlossaryInfo(glossaryPair)}
            {drawTMInfo(tmSuggest)}
        </InfoContainer>
    );
}

function drawGlossaryInfo(glossaryPair: GlossaryPairSearchResult | null) {
    if (!glossaryPair)
        return;

    return (
        <Container>
            <Box textAlign={"center"} m={1}>
                <Typography>{"Glossary " + glossaryPair.glossary}</Typography>
            </Box>
            <Divider/>
            <Grid container>
                <Grid item xs={3}>
                    <Bold>{"Source: "}</Bold>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{glossaryPair.source}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Bold>{"Target: "}</Bold>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{glossaryPair.target}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Bold>{"Comment: "}</Bold>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{glossaryPair.comment}</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

function drawTMInfo(tmSearchResult: TranslationMemorySearchResult | null) {
    if (!tmSearchResult)
        return;

    const diff = sanitizeHtml(tmSearchResult.diff, {allowedTags: ['del', 'ins']})

    return (
        <Stack marginY={1} spacing={1}>
            <Paper>
                <Stack spacing={1} padding={1}>
                    <Typography fontWeight={"bold"} fontSize={12}>Match difference</Typography>
                    <Container dangerouslySetInnerHTML={{__html: diff}}
                               sx={{
                                   wordWrap: 'break-word',
                                   del: {
                                       bgcolor: 'lightpink'
                                   },
                                   ins: {
                                       bgcolor: 'lightgreen'
                                   }
                               }}>
                    </Container>
                </Stack>
            </Paper>
            <Paper>
                <Stack spacing={1} padding={1}>
                    <Typography fontWeight={"bold"} fontSize={12}>Translation memory</Typography>
                    {drawProperty("Name", tmSearchResult.translationMemory)}
                    {tmSearchResult.editorName !== "" && drawProperty("Editor", tmSearchResult.editorName)}
                    {tmSearchResult.lastEditDateTime
                        && drawProperty("Changed", tmSearchResult.lastEditDateTime.toLocaleString())}
                </Stack>
            </Paper>
        </Stack>
    );
}

function drawProperty(header: string, value: string)
{
    return <Typography fontSize={12} sx={{ wordWrap: 'break-word' }}>
        <strong>{header + ": "}</strong>
        {value}
    </Typography>
}