import Dropzone from "react-dropzone";
import {
    Button,
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Immutable from "immutable";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import {
    setMultiLanguageModeAction,
    setStepAction
} from "../../../flux/project/editor/ProjectEditorActions";
import {
    handleFilesDrop,
    drawFilesLoadingArea,
    MainContainer,
    DropzoneContentContainer
} from "./FilesLoadingArea";

export default function ProjectFilesUploading() {
    const [files, setFiles]
        = useState<Immutable.List<File>>(Immutable.List<File>());
    const [multiLanguage, setMultiLanguage] = useState(false);

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setFiles(state.files);
            setMultiLanguage(state.multiLanguage);
        });
        return () => projectEditorListener.remove();
    });

    const drawSubmitButton = () => {
        return (
            <Button variant={"text"} onClick={() => setStepAction(1)}>
                {files.size === 0 ? "Skip" : "Next"}
            </Button>
        );
    }

    return (
        <Dropzone onDrop={acceptedFiles => handleFilesDrop(acceptedFiles, files)} noClick={true}
                  accept={{
                      'application/xliff+xml': ['.xliff', '.html', '.htm', '.docx', '.pptx', '.xlsx', '.po', '.md',
                          '.txt', '.properties']
                  }}>
            {({getRootProps, getInputProps, open}) => {
                return (<div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()}/>
                    <MainContainer>
                        <DropzoneContentContainer>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                                {drawFilesLoadingArea(files)}
                                <Stack alignItems={"center"} justifyContent={"space-around"}
                                       spacing={2}>
                                    <Button variant={"contained"} onClick={open}>Upload files</Button>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch onChange={event =>
                                                setMultiLanguageModeAction(event.target.checked)}
                                                    checked={multiLanguage}/>}
                                                          label={"Multi-language"}/>
                                    </FormGroup>
                                    {drawSubmitButton()}
                                </Stack>
                            </Stack>
                        </DropzoneContentContainer>
                    </MainContainer>
                </div>);
            }}
        </Dropzone>);
}