import Immutable from "immutable";
import {setProjectFilesAction} from "../../../flux/project/editor/ProjectEditorActions";
import ListItem from "@mui/material/ListItem";
import {Divider, IconButton, ListItemText, Stack, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {styled} from "@mui/material/styles";
import List from "@mui/material/List";

const FilesList = styled(List)({
    wordBreak: "break-word",
    height: 200,
    overflow: 'auto',
    bgColor: '#ffffff',
    opacity: '0.75'
});

export const MainContainer = styled('div')({
    height: 500,
    position: "relative"
});

export const DropzoneContentContainer = styled('div')({
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    width: 360,
    height: 360,
    backgroundColor: "#f5f5f5",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)"
});

export function handleFilesDrop(acceptedFiles: File[], files: Immutable.List<File>) {
    setProjectFilesAction(files.concat(acceptedFiles.filter(file =>
        !files.map(existingFile => existingFile.name).includes(file.name))));
}

export function drawFilesLoadingArea(files: Immutable.List<File>) {
    if (files.size !== 0) {
        return (
            <FilesList>
                {files.map((file, index) => [
                    <ListItem key={file.name}>
                        <ListItemText primary={file.name}/>
                        <IconButton edge="end" aria-label="delete"
                                    onClick={() => setProjectFilesAction(files.delete(index))}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItem>,
                    <Divider key={"divider-" + index}/>
                ])}
            </FilesList>
        );
    } else {
        return (
            <Stack alignItems={"center"} justifyContent={"center"}>
                <img src={"/CloudArrowUp.svg"} alt={"Upload files"} width={120} height={120}/>
                <Typography fontSize={20} fontWeight={500}>Upload files</Typography>
                <Typography fontSize={14} fontWeight={400}>or just drag them here</Typography>
            </Stack>
        );
    }
}