import Immutable from "immutable";

export enum ProjectEditorAction {
    SetIsUploadFilesOpen = "ProjectEditorAction.SetIsUploadFilesOpen",
    SetIsUpdateTranslationsOpen = "ProjectEditorAction.SetIsUpdateTranslationsOpen",
    SetFiles = "ProjectEditorAction.SetFiles",
    SetStep = "ProjectEditorAction.SetStep",
    SetMultiLanguageMode = "ProjectEditorAction.SetMultiLanguageMode"
}

interface ISetFilesPayload {
    action: ProjectEditorAction.SetFiles,
    files: Immutable.List<File>
}

interface ISetStep {
    action: ProjectEditorAction.SetStep,
    step: number
}

interface ISetIsUploadFilesOpen {
    action: ProjectEditorAction.SetIsUploadFilesOpen,
    uploadFilesOpen: boolean
}

interface ISetIsUpdateTranslationsOpen {
    action: ProjectEditorAction.SetIsUpdateTranslationsOpen,
    updateTranslationsOpen: boolean
}

interface ISetMultiLanguageMode {
    action: ProjectEditorAction.SetMultiLanguageMode,
    multiLanguageMode: boolean
}

export type ProjectEditorPayload = ISetFilesPayload |
    ISetStep |
    ISetIsUploadFilesOpen |
    ISetIsUpdateTranslationsOpen |
    ISetMultiLanguageMode;

export function setProjectFiles(files: Immutable.List<File>): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetFiles,
        files: files
    }
}

export function setStep(step: number): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetStep,
        step: step
    }
}

export function setIsUploadFilesOpen(uploadFilesOpen: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetIsUploadFilesOpen,
        uploadFilesOpen: uploadFilesOpen
    }
}

export function setIsUpdateTranslationsOpen(updateTranslationsOpen: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetIsUpdateTranslationsOpen,
        updateTranslationsOpen: updateTranslationsOpen
    }
}

export function setMultiLanguageMode(multiLanguage: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetMultiLanguageMode,
        multiLanguageMode: multiLanguage
    }
}