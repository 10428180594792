import {Container, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Segment} from "../../../../model/Segment";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {styled} from "@mui/material/styles";

const CommentsContainer = styled(Container)({
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%',
});


export default function ExternalCommentList() {
    const initialSegmentState = segmentStore.getState();
    const [currentSegment, setCurrentSegment]
        = useState<Segment | null>(initialSegmentState.segment);

    useEffect(() => {

        const segmentListener = segmentStore.addListener(() => {
            const state = segmentStore.getState();
            setCurrentSegment(state.segment);
        });

        return () => {
            segmentListener.remove();
        }
    });

    return (
        <CommentsContainer maxWidth={false}>
            <Grid item xs={2} textAlign={"left"} whiteSpace={"pre-wrap"}>
                {drawCurrentSegmentNote(currentSegment)}
            </Grid>
        </CommentsContainer>
    );
}

function drawCurrentSegmentNote(currentSegment: Segment | null) {
    if (currentSegment)
        return currentSegment.note;
    return null;
}
