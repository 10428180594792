import {GridActionsCellItem, GridRenderCellParams} from "@mui/x-data-grid";
import {Stack, Tooltip} from "@mui/material";
import React from "react";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import ProjectListItem from "../../model/ProjectListItem";
import {deleteProjectAction} from "../../flux/project/list/ProjectListActions";
import DeleteIcon from "@mui/icons-material/Delete";

type ProjectRowActionsProperties = {
    renderCellParams: GridRenderCellParams<ProjectListItem>,
}

export default function ProjectRowActions(props: ProjectRowActionsProperties) {
    return (
        <Stack>
            <PrivateComponent resource={AvailableResourceType.Projects} deleteAllow={true}>
                <GridActionsCellItem label={"Delete"} icon={<Tooltip title={"Delete"} arrow><DeleteIcon/></Tooltip>}
                                     onClick={e => handleDeleteClicked(e, props.renderCellParams.row)}/>
            </PrivateComponent>
        </Stack>
    );
}


function handleDeleteClicked(event: React.MouseEvent<HTMLElement>,
                             project: ProjectListItem) {
    deleteProjectAction(project.id);
    event.stopPropagation();
}