import {Record} from "immutable";
import {ISearchResult} from "./ISearchResult";
import {createSlate} from "../utils/slate/SlateUtils";

interface ITMSearchResult extends ISearchResult  {
    translationMemory: string,
    mode: string,
    scoring: number,
    diff: string,
    editorName: string,
    lastEditDateTime: Date | null
}

const SearchResultRecord = Record<ITMSearchResult>({
    translationMemory: '',
    source: '',
    target: '',
    sourceEditor: createSlate(),
    targetEditor: createSlate(),
    mode: '',
    scoring: 0,
    diff: '',
    editorName: '',
    lastEditDateTime: null
});

export default class TranslationMemorySearchResult extends SearchResultRecord {
}
