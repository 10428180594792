import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchEditorError, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {Segment} from "../model/Segment";
import {List} from "immutable";
import {Page} from "../model/Page";
import {
    IReplaceTranslationsResponse,
    ISegmentResponse,
    ITranslationResponse,
    toSegmentModel,
    toTranslationModel
} from "./model/SegmentApiModel";
import {Pageable} from "../model/Pageable";
import Translation from "../model/Translation";
import SegmentListFilter from "../flux/segment/list/SegmentListFilter";
import {EmbeddedResponseWithPagination, IGroupedListResponse, toFlatGroupedList,} from "../globals/ApiModels";
import GroupedListItem from "../model/GropedListItem";
import {SelectionModel} from "../model/SelectionModel";
import {ISearchResultResponse, toSearchResultModel} from "./model/TranslationMemoryApiModel";
import TranslationMemorySearchResult from "../model/TranslationMemorySearchResult";

export function fetchSegments(filter?: SegmentListFilter,
                              page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<SegmentListFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ISegmentResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toSegmentModel(value));
            return Page
                .fromBackendPage<Segment>(response.data.page)
                .setList(List(list))
        })
        .catch(error => {
            dispatchEditorError(error);
            return new Page<Segment>();
        })
}

export function fetchGroupedSegments(filter?: SegmentListFilter,
                                     page?: Pageable) {
    let url = buildUrl(
        endpoint + "/searchGrouped",
        {},
        toParameters<SegmentListFilter>(filter, page, ["File", "Language"]));

    return axios
        .get(url)
        .then((response: AxiosResponse<IGroupedListResponse<ISegmentResponse>>) => {
            const flatList = toFlatGroupedList<ISegmentResponse>(response.data._embedded)
                .map(value => {
                    if (value.element)
                        value.set("element", toSegmentModel(value.element))

                    return value;
                })
                .map(value => value as GroupedListItem<Segment>)
            return Page
                .fromBackendPage<GroupedListItem<Segment>>(response.data.page)
                .setList(flatList)
        })
        .catch(error => {
            dispatchEditorError(error);
            return new Page<GroupedListItem<Segment>>();
        })
}

export function saveTranslation(segmentId: number,
                                languageCode: string,
                                target: string,
                                workflowStepId: number) {
    return axios
        .post(endpoint + "/" + segmentId + "/translation",
            {
                languageCode: languageCode,
                target: target,
                workflowStepId: workflowStepId
            })
        .then((response: AxiosResponse<ITranslationResponse>) => {
            return toTranslationModel(response.data);
        })
        .catch(reason => {
            if (reason.response.status === 404)
                return Translation.UnavailableTranslation;
            dispatchEditorError(reason);
        });
}

export function deleteTranslations(selection: SelectionModel, filter: SegmentListFilter) {
    return axios
        .put(endpoint + "/clear-translations",
            {
                filter: filter.toObject(),
                selection: selection
            })
        .catch(reason => {
            dispatchEditorError(reason);
        });
}

export function lockTranslations(selection: SelectionModel, filter: SegmentListFilter) {
    return axios
        .post(`${endpoint}/lock`,
            {
                filter: filter.toObject(),
                selection: selection,
            })
        .catch(reason => {
            dispatchEditorError(reason);
        });
}

export function unlockTranslations(selection: SelectionModel, filter: SegmentListFilter) {
    return axios
        .post(`${endpoint}/unlock`,
            {
                filter: filter.toObject(),
                selection: selection,
            })
        .catch(reason => {
            dispatchEditorError(reason);
        });
}

export function setWorkflow(selection: SelectionModel, filter: SegmentListFilter, workflowStepId: number) {
    return axios
        .post(`${endpoint}/set-workflow`,
            {
                filter: filter.toObject(),
                selection: selection,
                workflowId: workflowStepId
            })
        .catch(reason => {
            dispatchEditorError(reason);
        });
}

export function fetchTranslations(segmentId: number, languageCode: string) {
    return axios
        .get(endpoint + "/" + segmentId + "/" + languageCode + "/searchInTranslationMemory")
        .then((response: AxiosResponse<ISearchResultResponse[]>) => {
            const list = response
                .data
                .map(value => toSearchResultModel(value));
            return new Page<TranslationMemorySearchResult>().setList(List(list));
        })
        .catch(dispatchFetchPageError<TranslationMemorySearchResult>);
}

export function getSegment(segmentId: number, language: string) {
    return axios
        .get(endpoint + "/" + segmentId + "/" + language)
        .then((response: AxiosResponse<ISegmentResponse>) => {
            return toSegmentModel(response.data);
        })
}

export function replaceTranslations(filter: SegmentListFilter, textToReplace: string, replacement: string) {
    return axios
        .put(endpoint + "/replace-translations", {
            filter: filter.toObject(),
            textToReplace: textToReplace,
            replacement: replacement
        })
        .then((response: AxiosResponse<IReplaceTranslationsResponse>) => response.data.replaced)
        .catch(editor => {
            dispatchEditorError(editor);
            return null;
        });
}

const endpoint = "/api/projects/segments";
