import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, Menu, MenuItem, Stack, Tooltip} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import {getGridStringOperators, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {useSearchParams} from "react-router-dom";
import {CatFile, FileState, isPending} from "../../../../model/CatFile";
import Typography from "@mui/material/Typography";
import ProjectFileActions from "./actions/ProjectFileActions";
import {Search} from "@mui/icons-material";
import Project from "../../../../model/Project";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {
    setIsOpenUpdateTranslationsAction,
    setIsOpenUploadFilesAction
} from "../../../../flux/project/editor/ProjectEditorActions";
import {setSearchMenuOpenAction} from "../../../../flux/project/search/ProjectSearchActions";
import PrivateComponent from "../../../PrivateComponent";
import {AvailableResourceType} from "../../../../model/AvailableResources";
import ListView from "../../../common/ListView";
import {catFileListActions} from "../../../../flux/cat-files/CatFileListActions";
import {
    GridRowAnyOfOperator,
    GridRowStringOperatorsDefault,
    GridRowStringOperatorsWithAnyOf
} from "../../../../globals/Constants";
import {selectedCatFilesBuildActions} from "../../../../flux/common/build/TranslationsBuildActions";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {getCatFilesPath} from "../../../../routes/project/tabs/CatFilesRoute";
import {useProject} from "../../ProjectView";
import CatFilesFilter from "../../../../flux/cat-files/CatFilesFilter";
import {useInterval} from "../../../../globals/CustomHooks";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import SelectCatFileStatus from "./SelectCatFileStatus";
import {CommonLink} from "../../../../globals/CommonComponents";

const FileProgress = styled(CircularProgress)({
    minWidth: 20
});

export default function CatFiles() {
    const {project} = useProject();

    const [searchParams] = useSearchParams();
    const [build, setBuild] = useState(false);
    const [waitingFiles, setWaitingFiles] = useState(true);
    const [filter, setFilter]
        = useState<CatFilesFilter | null>(null);
    const [uploadMenuAnchor, setUploadMenuAnchor]
        = useState<HTMLElement | null>(null);
    const uploadMenuOpen = Boolean(uploadMenuAnchor);
    const [downloadMenuAnchor, setDownloadMenuAnchor]
        = useState<HTMLElement | null>(null);
    const downloadMenuOpen = Boolean(downloadMenuAnchor);

    useEffect(() => {
        const projectBuildListener = selectedCatFilesBuildActions.addListener(() => {
            const state = selectedCatFilesBuildActions.state;
            const foundBuild = state.building.find(value => value.id === project.id);
            setBuild(foundBuild !== undefined);
        });

        const catFilesListener = catFileListActions.addListener(() => {
            const state = catFileListActions.state;
            let isWaitingFiles = true;
            if (state.page) {
                isWaitingFiles = !state.page.list.every(catFile => !isPending(catFile));
            }
            setWaitingFiles(isWaitingFiles);
        });

        return () => {
            projectBuildListener.remove();
            catFilesListener.remove();
        }
    }, []);

    useInterval(() => {
        catFileListActions.refresh(false);
    }, 2000);

    useEffect(() => {
        const filter = CatFilesFilter.fromSearchParams(
            project.id,
            project.targets.map(value => value.toString()),
            searchParams);
        setFilter(filter);
        catFileListActions.setFilter(filter).then(() => catFileListActions.fetch(filter));

    }, [project]);

    return (
        <Stack sx={{height: '100%'}}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <Box>
                        <IconButton onClick={e => setUploadMenuAnchor(e.currentTarget)}
                                    disabled={isBusy(build, waitingFiles)}>
                            {build && <CircularProgress sx={{position: 'absolute'}}/>}
                            <UploadIcon/>
                        </IconButton>
                        <Menu open={uploadMenuOpen} onClose={() => setUploadMenuAnchor(null)}
                              anchorEl={uploadMenuAnchor}>
                            <MenuItem onClick={() => handleUploadFilesClicked(setUploadMenuAnchor)}
                                      disabled={isBusy(build, waitingFiles)}>
                                Upload files
                            </MenuItem>
                            <MenuItem onClick={() => handleUpdateTranslationsClicked(setUploadMenuAnchor)}
                                      disabled={isBusy(build, waitingFiles)}>
                                Update translations
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <IconButton onClick={e => setDownloadMenuAnchor(e.currentTarget)}
                                    disabled={isBusy(build, waitingFiles)}>
                            {build && <CircularProgress sx={{position: 'absolute'}}/>}
                            <DownloadIcon/>
                        </IconButton>
                        <Menu open={downloadMenuOpen} onClose={() => setDownloadMenuAnchor(null)}
                              anchorEl={downloadMenuAnchor}>
                            <MenuItem onClick={() => handleBuildTranslationsClicked(project, setDownloadMenuAnchor)}>
                                Download translations
                            </MenuItem>
                            <MenuItem onClick={() => handleBuildXliffClicked(project, setDownloadMenuAnchor)}>
                                Download xliff
                            </MenuItem>
                        </Menu>
                    </Box>
                    <PrivateComponent resource={AvailableResourceType.CatFiles} deleteAllow={true}>
                        <IconButton onClick={handleDeleteClicked} disabled={isBusy(build, waitingFiles)}>
                            <DeleteIcon/>
                        </IconButton>
                    </PrivateComponent>
                </Stack>
                <Stack direction={"row"} spacing={0.5}>
                    <IconButton onClick={handleSearchClicked}>
                        <Search/>
                    </IconButton>
                </Stack>
            </Stack>
            {filter && <ListView actions={catFileListActions}
                                 columns={getColumns(project)}
                                 getRowHeight={() => Math.max(project.getWorkflowSteps().size * 25, 50)}
                                 getRowId={(row: CatFile) => row.id.toString() + row.language}
                                 navigateProps={{navigator: getCatFilesPath}}
                                 initialFilter={filter}
                                 useNewSelectionModel={true}/>
            }
        </Stack>
    );
}

function getColumns(project: Project | null): GridColDef<CatFile>[] {
    return [
        {
            field: 'file',
            headerName: 'File',
            type: 'string',
            flex: 5,
            renderCell: (params: GridRenderCellParams<any, CatFile>) => {
                const currentFile: CatFile = params.row;
                const fileName = (currentFile.path.length === 0 ? '' : currentFile.path + '\\')
                    + currentFile.name;
                return (
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip title={fileName}>
                            <CommonLink to={currentFile.state !== FileState.Available || !project
                                ? "#"
                                : getSegmentPath(
                                    new SegmentListFilter({
                                        projectId: project.id,
                                        catFile: currentFile,
                                        language: currentFile.language.id
                                    }),
                                    0)}>
                                {fileName}
                            </CommonLink>
                        </Tooltip>
                        {isPending(currentFile) && <FileProgress size={20} sx={{minWidth: 20}}/>}
                        {currentFile.state === FileState.Error && <Typography color={"red"}>Error</Typography>}
                    </Box>
                );
            },
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'status',
            sortable: false,
            headerName: 'Progress',
            type: 'string',
            renderCell: (params: GridRenderCellParams<CatFile>) => drawStatistic(params.row),
            flex: 3,
            filterOperators: getGridStringOperators()
                .filter(operator => GridRowAnyOfOperator.includes(operator.value))
                .map(operator => ({
                    ...operator,
                    InputComponent: operator.InputComponent ? SelectCatFileStatus : undefined
                }))
        },
        {
            field: 'language',
            sortable: false,
            headerName: 'Language',
            type: 'string',
            flex: 1,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsWithAnyOf.includes(operator.value))
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params: GridRenderCellParams<CatFile>) => {
                if (!project)
                    return;
                return (
                    <ProjectFileActions renderCellParams={params} projectId={project.id}/>
                );
            }
        }
    ]
}

function handleDeleteClicked() {
    catFileListActions.deleteSelected();
}

function handleSearchClicked() {
    setSearchMenuOpenAction(true);
}

function handleUploadFilesClicked(setUploadMenuAnchor: (anchor: HTMLElement | null) => void) {
    setIsOpenUploadFilesAction(true);
    setUploadMenuAnchor(null);
}

function handleUpdateTranslationsClicked(setUploadMenuAnchor: (anchor: HTMLElement | null) => void) {
    setIsOpenUpdateTranslationsAction(true);
    setUploadMenuAnchor(null)
}

function handleBuildTranslationsClicked(project: Project, setBuildMenuAnchor: (anchor: HTMLElement | null) => void) {
    selectedCatFilesBuildActions.build(project, false);
    setBuildMenuAnchor(null);
}

function handleBuildXliffClicked(project: Project, setBuildMenuAnchor: (anchor: HTMLElement | null) => void) {
    selectedCatFilesBuildActions.build(project, true);
    setBuildMenuAnchor(null);
}

function isBusy(build: boolean, waitingFiles: boolean): boolean {
    return build || waitingFiles;
}

// TODO: Calculate statistic fully on server side
function drawStatistic(catFile: CatFile) {
    const byWorkflow = catFile
        .groupedStatistic
        .translatedByWorkflow(catFile.language).map(value => {
            return (
                <Stack direction={"row"}
                       key={"cat-file-statistic-" + catFile.id + "-" + value.presentationWithWorkflow}>
                    <CircularProgress value={value.percent} variant={"determinate"} size={14}/>
                    <Typography variant={"caption"} fontWeight={"bold"} marginLeft={1}>
                        {value.presentationWithWorkflow}
                    </Typography>
                </Stack>
            )
        });
    return (
        <Stack direction={"column"}>
            {byWorkflow}
        </Stack>
    );
}
