import {Segment} from "../../../model/Segment";
import {Page} from "../../../model/Page";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";

export enum SegmentPayloadAction {
    SetSearchResults = "SegmentPayloadAction.SetSearchResults",
    SetGlossaryPairs = "SegmentPayloadAction.SetGlossaryPairs",
    SelectGlossaryPair = "SegmentPayloadAction.SelectGlossaryPair",
    SetSegment = "SegmentPayloadAction.SetSegment",
    SelectTMSuggest = "SegmentPayloadAction.SelectTMSuggest"
}

interface ISetSearchResultsPayload {
    action: SegmentPayloadAction.SetSearchResults,
    searchResults: Page<TranslationMemorySearchResult>
}

interface ISetGlossaryPairsPayload {
    action: SegmentPayloadAction.SetGlossaryPairs,
    glossaryPairs: Page<GlossaryPairSearchResult>
}

interface ISetSegmentPayload {
    action: SegmentPayloadAction.SetSegment,
    segment: Segment
}

interface ISelectGlossaryPairPayload {
    action: SegmentPayloadAction.SelectGlossaryPair,
    glossaryPair: GlossaryPairSearchResult | null
}

interface ISelectTMSuggestPayload {
    action: SegmentPayloadAction.SelectTMSuggest,
    tmSuggest: TranslationMemorySearchResult | null
}

export type SegmentEditorPayload = ISetSearchResultsPayload
    | ISetSegmentPayload
    | ISetGlossaryPairsPayload
    | ISelectGlossaryPairPayload
    | ISelectTMSuggestPayload;

export function setSearchResults(searchResults: Page<TranslationMemorySearchResult>): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetSearchResults,
        searchResults: searchResults
    }
}

export function setGlossaryPairs(glossaryPairs: Page<GlossaryPairSearchResult>): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetGlossaryPairs,
        glossaryPairs: glossaryPairs
    }
}

export function setSegment(segment: Segment): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetSegment,
        segment: segment
    }
}

export function selectGlossaryPair(searchResult: GlossaryPairSearchResult | null): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SelectGlossaryPair,
        glossaryPair: searchResult
    }
}

export function selectTMSuggest(tmSuggest: TranslationMemorySearchResult | null): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SelectTMSuggest,
        tmSuggest: tmSuggest
    }
}