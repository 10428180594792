import { Checkbox, LinearProgress, Stack, Typography } from "@mui/material";
import {SegmentCell, SegmentsTableRow} from "../../../../globals/CommonComponents";
import {Segment} from "../../../../model/Segment";

type LoadingSegmentViewProperties = {
    position: number,
    isEditing: boolean,
    isLocked: boolean,
    style: any,
    segment: Segment
}

export default function LoadingSegmentView(props: LoadingSegmentViewProperties)
{
    return (
        <SegmentsTableRow sx={{padding: 0, margin: 0}}
                          isEditing={props.isEditing}
                          isLocked={props.isLocked}
                          style={{...props.style}}
                          direction={"row"}>
            <SegmentCell sx={{
                minWidth:'35px',
                width: '35px',
                position: 'relative',
                display:"flex",
                paddingLeft: '0 !important',
                alignItems: 'flex-start'}}>
                <Checkbox id={`segment-selected-checkbox-${props.position}`} sx={{padding: 0}}/>
            </SegmentCell>
            <SegmentCell style={{paddingLeft: 20, margin: 0, maxWidth: '50px', whiteSpace: 'nowrap'}}>
                <Stack>
                    <Typography textAlign={"left"} sx={{padding: 0, margin: 0,}}>{props.segment.order + 1}</Typography>
                </Stack>
            </SegmentCell>
            <SegmentCell sx={{padding: 0, margin: 0,}} style={{width: '100%'}}>
                <LinearProgress sx={{width: '100%'}}/>
            </SegmentCell>
        </SegmentsTableRow>
    );
}