import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {Link, NavigateFunction, Outlet, useMatches, useNavigate} from "react-router-dom";
import {ProjectsId, ProjectsPath} from "./project/ProjectsRoute";
import {Grid2, ListItemIcon, ListItemText} from "@mui/material";
import {NewProjectId, NewProjectPath} from "./project/NewProjectRoute";
import {Params} from "@remix-run/router";
import {PretranslateId} from "./project/PretranslateRoute";
import AddIcon from '@mui/icons-material/Add';
import AlertView from "../components/AlertView";
import {getGlossariesPath, GlossariesId} from "./GlossariesRoute";
import {useKeycloak} from "@react-keycloak/web";
import {PlaceholdersId, PlaceholdersPath} from "./placeholders/PlaceholdersSetsRoute";
import {NewPlaceholdersSetId} from "./placeholders/NewPlaceholdersSetRoute";
import {PlaceholdersSetId} from "./placeholders/PlaceholdersSetRoute";
import {ProjectSearchId} from "./project/ProjectSearchRoute";
import SearchInProject, {SearchInProjectDrawerWidth} from "../components/project/tabs/files/SearchInProject";
import projectSearchStore from "../flux/project/search/ProjectSearchStore";
import authStore from "../flux/auth/AuthStore";
import Project from "../model/Project";
import PlaceholdersSet from "../model/PlaceholdersSet";
import {AlertType} from "../model/Alert";
import PrivateComponent from "../components/PrivateComponent";
import {AvailableResourceType} from "../model/AvailableResources";
import {getTranslationMemoriesPath, TranslationMemoriesId} from './TranslationMemoriesRoute';
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import GlossaryFilter from "../flux/glossary/GlossaryFilter";
import {EditorId} from './EditorRoute';
import {AdminPanePath} from "./admin/AdminPaneRoute";
import {Logout} from "@mui/icons-material";
import {ProjectId} from "./project/ProjectRoute";
import BackButton from "../components/top-navigation/BackButton";
import {ProjectReportId} from "./project/ProjectReportRoute";
import CatFileDropdown from "../components/top-navigation/CatFileDropdown";
import LanguageDropdown from '../components/top-navigation/LanguageDropdown';
import TranslationStatistics from "../components/top-navigation/TranslationStatistics";
import {User} from "../model/User";
import {VendorsListPath} from "./vendor/VendorsListRoute";

const leftDrawerWidth = 180;
const collapsedDrawerWidth = 60;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'leftDrawerOpen'
        && prop !== 'rightDrawerOpen'
        && prop !== 'editorOpen'
})<{
    leftDrawerOpen?: boolean;
    rightDrawerOpen?: boolean;
    editorOpen?: boolean;
}>(({theme, leftDrawerOpen, rightDrawerOpen, editorOpen}
) => ({
    flexGrow: 1,
    height: '100%',
    width: `calc(100% - ${(leftDrawerOpen ? leftDrawerWidth : collapsedDrawerWidth)
    + (rightDrawerOpen ? SearchInProjectDrawerWidth : 0)}px)`,
    padding: editorOpen ? 0 : theme.spacing(3),
    marginLeft: `${leftDrawerOpen ? leftDrawerWidth : 0}px`,
    marginRight: `${rightDrawerOpen ? SearchInProjectDrawerWidth : 0}px`,
    ...(leftDrawerOpen && {
        marginLeft: 0
    }),
    ...(rightDrawerOpen && {
        marginRight: 0
    })
}));

interface AppBarProps extends MuiAppBarProps {
    leftDrawerOpen?: boolean;
    rightDrawerOpen?: boolean;
    isEditorOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'leftDrawerOpen'
        && prop !== 'rightDrawerOpen'
        && prop !== 'isEditorOpen',
})<AppBarProps>(({leftDrawerOpen, rightDrawerOpen, isEditorOpen}) => ({
    width: `calc(100% - ${(leftDrawerOpen ? leftDrawerWidth : collapsedDrawerWidth)
    + (rightDrawerOpen && !isEditorOpen ? SearchInProjectDrawerWidth : 0)}px)`,

    marginLeft: `${leftDrawerOpen && !isEditorOpen ? leftDrawerWidth : leftDrawerWidth}px`,
    marginRight: `${rightDrawerOpen && !isEditorOpen ? SearchInProjectDrawerWidth : 0}px`
}));

const DrawerHeader = styled('div')(
    ({theme}): any => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    }));

interface MainContainerProps {
    editorOpen?: boolean;
}

const MainContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'editorOpen'
})<MainContainerProps>(
    ({theme, editorOpen}): any => ({
        height: editorOpen ? "100%" : `calc(100% - ${theme.mixins.toolbar.minHeight}px)`
    }));

export default function Root() {
    const initialProjectSearchState = projectSearchStore.getState();
    const initialAuthState = authStore.getState();
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
    const [rightDrawerOpen, setRightDrawerOpen]
        = useState(initialProjectSearchState.isOpen);
    const [user, setUser] = useState(initialAuthState.user);
    const matches = useMatches() as MatchesType;
    const navigate = useNavigate();
    const {keycloak} = useKeycloak();
    const isEditorOpen = matches[matches.length - 1].id === EditorId;

    useEffect(() => {
        const projectSearchListener = projectSearchStore.addListener(() => {
            const state = projectSearchStore.getState();
            setRightDrawerOpen(state.isOpen);
        });

        const authListener = authStore.addListener(() => {
            const state = authStore.getState();
            setUser(state.user);
        });

        return () => {
            projectSearchListener.remove();
            authListener.remove();
        }
    }, []);

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <CssBaseline/>
            <AppBar position="fixed" leftDrawerOpen={leftDrawerOpen} rightDrawerOpen={rightDrawerOpen}
                    isEditorOpen={isEditorOpen}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    '& > *:not(:last-child)': {
                                        marginRight: '20px'
                                    }
                                }}>
                        {toolbarLabel(matches)}
                        <BackButton isOpen={toolbarBackButton(matches)}/>
                        <CatFileDropdown isOpen={toolbarEditorNavigation(matches)}/>
                        <LanguageDropdown isOpen={toolbarEditorNavigation(matches)}/>
                        <TranslationStatistics isOpen={toolbarEditorNavigation(matches)}/>
                    </Typography>
                    {getUserInitials(user)}
                    <IconButton onClick={() => keycloak.logout()}
                                aria-label="logout"
                                color="inherit"
                    >
                        <Logout/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: leftDrawerOpen ? leftDrawerWidth : 60,
                    transition: 'width 0.5s ease-in-out',
                    padding: '0px 8px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: leftDrawerOpen ? leftDrawerWidth : 60,
                        boxSizing: 'border-box',
                        transition: 'width 0.5s ease-in-out',
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: leftDrawerOpen ? 'initial' : 'center',
                    },
                    '& .MuiListItem-root': {
                        padding: 0
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: 36
                    },
                    '& .MuiListItemText-root': {
                        whiteSpace: 'nowrap',
                    },
                    '& .MuiTypography-root': {
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                    }
                }}
                variant="permanent"
                anchor="left"
                open={leftDrawerOpen}
            >
                <Grid2
                    container
                    alignItems="center"
                    sx={{
                        padding: 0,
                        margin: 0,
                        height: '64px'
                    }}
                >
                    <Grid2
                        size={10}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            padding: 0,
                            margin: 0,
                            height: '100%'
                        }}
                    >
                        {leftDrawerOpen ? (
                            <IconButton
                                sx={{
                                    width: '100%',
                                    borderRadius: 0,
                                    margin: 0,
                                    transform: 'scale(0.8)',
                                    justifyContent: 'flex-start',
                                    padding: 0,
                                }}
                                onClick={() => handleLogoClicked(navigate)}
                            >
                                <img src="/Logo.svg" alt="logo" height="100%"/>
                            </IconButton>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                            </Box>
                        )}
                    </Grid2>

                    <Grid2
                        size={2}
                        sx={{
                            padding: 0,
                            margin: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            height: '100%',
                        }}
                    >
                        <IconButton
                            onClick={() => handleDrawerClose(setLeftDrawerOpen, leftDrawerOpen)}
                            sx={{margin: 0, padding: 0}}
                        >
                            {leftDrawerOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </Grid2>
                </Grid2>
                <List>
                    <PrivateComponent resource={AvailableResourceType.Projects} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={ProjectsPath}>
                                <ListItemIcon>
                                    <img src={"/ProjectsIcon.svg"} alt={"projects"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText primary={"Projects"}/>}
                            </ListItemButton>
                            {leftDrawerOpen &&
                                <PrivateComponent resource={AvailableResourceType.Projects} writeAllow={true}>
                                    <IconButton component={Link} to={NewProjectPath}>
                                        <AddIcon/>
                                    </IconButton>
                                </PrivateComponent>}
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.TranslationMemories} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link}
                                            to={getTranslationMemoriesPath(new TranslationMemoryFilter())}>
                                <ListItemIcon>
                                    <img src={"/TmIcon.svg"} alt={"translation memories"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText
                                    primary="Translation memories"
                                    style={{textAlign: 'center', whiteSpace: 'normal'}}
                                />}
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Glossaries} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={getGlossariesPath(new GlossaryFilter())}>
                                <ListItemIcon>
                                    <img src={"/GlossariesIcon.svg"} alt={"glossaries"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText primary={"Glossaries"}/>}
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Placeholders} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={PlaceholdersPath}>
                                <ListItemIcon>
                                    <img src={"/PlaceholdersIcon.svg"} alt={"placeholders"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText primary={"Placeholders"}/>}
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Vendors} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={VendorsListPath}>
                                <ListItemIcon>
                                    <img src={"/VendorsIcon.svg"} alt={"vendor"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText primary={"Vendors"}/>}
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Admin} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link}
                                            to={AdminPanePath}>
                                <ListItemIcon>
                                    <img src={"/AdminIcon.svg"} alt={"admin"}/>
                                </ListItemIcon>
                                {leftDrawerOpen && <ListItemText primary={"Admin"}/>}
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                </List>
            </Drawer>
            <Main leftDrawerOpen={leftDrawerOpen} rightDrawerOpen={rightDrawerOpen} editorOpen={isEditorOpen}>
                {!isEditorOpen && <DrawerHeader/>}
                <MainContainer editorOpen={isEditorOpen}>
                    <Outlet/>
                </MainContainer>
                <AlertView type={AlertType.Global}/>
            </Main>
            {!toolbarBackButton(matches) && rightDrawerOpen && (<SearchInProject/>)}
        </Box>
    );
}

function toolbarLabel(matches: MatchesType): string {
    const lastMatch = matches[matches.length - 1];
    switch (lastMatch.id) {
        case TranslationMemoriesId:
            return "Translation memories";
        case ProjectsId:
            return "Projects";
        case GlossariesId:
            return "Glossaries";
        case PlaceholdersId:
            return "Placeholders";
        case NewProjectId:
            return "Add project";
        case ProjectSearchId:
            return "Project search";
        case NewPlaceholdersSetId:
            return "Add preset";
        case PlaceholdersSetId:
            return "Placeholders set " + (lastMatch.data as PlaceholdersSet).name;
        case ProjectId:
            return "Project " + (lastMatch.data as Project).name;
        case PretranslateId:
            return "Project " + (lastMatch.data as Project).name + " pretranslation";
        case ProjectReportId:
            return "";
        case EditorId:
            return "";
    }
    return "1CAT";
}

function toolbarBackButton(matches: MatchesType): boolean {
    const lastMatch = matches[matches.length - 1];
    switch (lastMatch.id) {
        case ProjectReportId:
            return true;
        case EditorId:
            return true;
    }
    return false;
}

function toolbarEditorNavigation(matches: MatchesType): boolean {
    const lastMatch = matches[matches.length - 1];
    switch (lastMatch.id) {
        case EditorId:
            return true;
    }
    return false;
}

function handleDrawerClose(setLeftDrawerOpen: (value: boolean) => void, leftDrawerOpen: boolean) {
    setLeftDrawerOpen(!leftDrawerOpen);
}

function handleLogoClicked(navigate: NavigateFunction) {
    navigate("/");
}

export type MatchesType = {
    id: string;
    pathname: string;
    params: Params;
    data: Project | PlaceholdersSet | null;
    handle: { crumb: (project: Project | PlaceholdersSet | null) => JSX.Element };
}[];

function getUserInitials(user: User | null) {
    let initials: string = "";
    if (user?.firstName && user?.lastName)
        initials = user?.firstName.charAt(0) + user?.lastName.charAt(0);
    return (<Typography
            sx={{
                fontFamily: 'Rubik',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '20px',
                letterSpacing: '5px',
            }}
        >
            {initials}
        </Typography>
    );
}
