import {ReduceStore} from "flux/utils";
import {SegmentEditorPayload, SegmentPayloadAction} from "./SegmentEditorPayload";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {Page} from "../../../model/Page";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";
import segmentListStore from "../list/SegmentListStore";

interface ISegmentState {
    searchResults: Page<TranslationMemorySearchResult>,
    tmSuggest: TranslationMemorySearchResult | null,
    glossaryPairs: Page<GlossaryPairSearchResult>,
    selectedGlossaryPair: GlossaryPairSearchResult | null,
    segmentId: number
}

const SegmentStateFactory = Immutable.Record<ISegmentState>({
    searchResults: new Page<TranslationMemorySearchResult>(),
    tmSuggest: new TranslationMemorySearchResult(),
    glossaryPairs: new Page<GlossaryPairSearchResult>(),
    selectedGlossaryPair: null,
    segmentId: -1
});

export class SegmentState extends SegmentStateFactory {
    get segment() {
        // segment should be from list for properly slate update
        // optimize it if it needs
        const segment = segmentListStore
            .getState()
            .items
            .find(value => value.id === this.segmentId);

        if (segment)
            return segment;
        else
            return null;
    }
}

export class SegmentEditorStore extends ReduceStore<SegmentState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): SegmentState {
        return new SegmentState();
    }

    reduce(state: SegmentState, payload: SegmentEditorPayload): SegmentState {
        switch (payload.action) {
            case SegmentPayloadAction.SetSearchResults:
                return state.set("searchResults", payload.searchResults);
            case SegmentPayloadAction.SelectTMSuggest:
                return state.set("tmSuggest", payload.tmSuggest);
            case SegmentPayloadAction.SetGlossaryPairs:
                return state.set("glossaryPairs", payload.glossaryPairs);
            case SegmentPayloadAction.SetSegment:
                if (payload.segment.id === state.segmentId)
                    return state.set("segmentId", payload.segment.id)
                return state
                    .set("segmentId", payload.segment.id)
                    .set("searchResults", new Page<TranslationMemorySearchResult>())
                    .set("glossaryPairs", new Page<GlossaryPairSearchResult>())
                    .set("selectedGlossaryPair", null);
            case SegmentPayloadAction.SelectGlossaryPair:
                return state.set("selectedGlossaryPair", payload.glossaryPair);
            default:
                return state;
        }
    }
}

const segmentStore = new SegmentEditorStore();
export default segmentStore;