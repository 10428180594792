import {RenderElementProps} from "slate-react";
import {Tooltip} from "@mui/material";
import React from "react";
import {Placeholder, SlateParagraph} from "../../../../../globals/CommonComponents";
import {escapePlaceholderContent, InlineChromiumBugfix} from "../../../../../utils/slate/SlateUtils";
import {SlateEntityType} from "../../../../../utils/slate/SlateModels";

export function RenderedTargetElement(props: RenderElementProps) {
    const {attributes, children, element} = props;
    switch (element.type) {
        case SlateEntityType.Paragraph:
            return <SlateParagraph {...attributes}>{children}</SlateParagraph>
        case SlateEntityType.Placeholder:
            if (!element.render)
                return;
            return <Tooltip title={escapePlaceholderContent(element.content)}>
                <Placeholder {...attributes}
                             onCopy={event => event.preventDefault()}
                             contentEditable={false}>
                    <InlineChromiumBugfix/>
                    {children}
                    <InlineChromiumBugfix/>
                </Placeholder>
            </Tooltip>;
        case SlateEntityType.EscapedText:
            return <span {...attributes} style={{color: "#9c9c9c"}}>
                <InlineChromiumBugfix/>
                {children}
                <InlineChromiumBugfix/>
            </span>;
        default:
            return <p {...attributes}>{children}</p>;
    }
}