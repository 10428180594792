import Box from "@mui/material/Box";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Input, Menu, MenuItem, Tooltip} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import {CatFile, isPending} from "../../../../../model/CatFile";
import React, {useEffect, useRef, useState} from "react";
import useFileUpload from "react-use-file-upload";
import {updateTranslationsAction, uploadFileAction} from "../../../../../flux/project/page/ProjectPageActions";

type UploadFileActionProps = {
    catFile: CatFile,
    projectId: number
}

enum UploadMode {
    Upload,
    UpdateTranslations
}

export default function UploadFileAction(props: UploadFileActionProps) {

    const openFileElement = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState('');
    const [uploadMode, setUploadMode] = useState(UploadMode.Upload);
    const {files, setFiles} = useFileUpload();
    const [anchor, setAnchor]
        = useState<HTMLElement | null>(null);
    const open = Boolean(anchor);

    useEffect(() => {
        if (files.length > 0) {
            const currentRow = props.catFile;
            switch(uploadMode) {
                case UploadMode.Upload:
                    uploadFileAction(files[0], props.catFile, props.projectId, fileName, currentRow.multiLanguage);
                    break;
                case UploadMode.UpdateTranslations:
                    updateTranslationsAction(files[0], props.catFile, props.projectId);
                    break;
            }
        }
    }, [files]);

    const handleRenewClicked = () => {
        setFileName(props.catFile.name);
        setUploadMode(UploadMode.Upload);
        const current = openFileElement.current;
        if (!current)
            return;
        current.click();
        setAnchor(null);
    }

    const handleUpdateTranslations = () => {
        setFileName(props.catFile.name);
        setUploadMode(UploadMode.UpdateTranslations);
        const current = openFileElement.current;
        if (!current)
            return;
        current.click();
        setAnchor(null);
    }

    return <Box>
        <Input type={"file"} inputProps={{accept: ".xliff,.html,.properties"}} inputRef={openFileElement}
               onChange={(e: any) => setFiles(e)} style={{display: 'none'}}/>
        <GridActionsCellItem label={"Update"} icon={<Tooltip title={"Update"} arrow><UploadIcon/></Tooltip>}
                             onClick={e => setAnchor(e.currentTarget)}
                             disabled={isPending(props.catFile)}/>
        <Menu open={open} onClose={() => setAnchor(null)} anchorEl={anchor}>
            <MenuItem onClick={handleRenewClicked}>Upload</MenuItem>
            <MenuItem onClick={handleUpdateTranslations}>Update translations</MenuItem>
        </Menu>
    </Box>;
}