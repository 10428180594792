import Glossary from "../../model/Glossary";
import GlossaryPairSearchResult from "../../model/GlossaryPairSearchResult";
import {Build, BuildState} from "../../model/Build";
import {List} from "immutable";
import {createSlate} from "../../utils/slate/SlateUtils";

export function toGlossaryModel(response: IGlossaryResponse) {
    return new Glossary({
        id: response.id,
        name: response.name
    })
}

export function toGlossaryPairSearchResultModel(response: IPairSearchResponse) {
    const searchResult = response.searchResult;
    return List<GlossaryPairSearchResult>(searchResult.map(result =>
        new GlossaryPairSearchResult({
            glossary: result.glossaryId,
            source: result.source,
            sourceEditor: createSlate(result.source),
            target: result.translation,
            targetEditor: createSlate(result.translation),
            comment: result.comment
        })));
}

export function toGlossaryBuildModel(response: IGlossaryBuildResponse) {
    return new Build({
        id: response.id,
        state: BuildState[response.state as keyof typeof BuildState],
    })
}

export interface IGlossaryResponse {
    id: number,
    name: string
}

export interface IPairSearchResponse {
    searchResult: IFoundTranslationResponse[]
}

interface IFoundTranslationResponse {
    glossaryId: string,
    source: string,
    translation: string,
    comment: string
}

export interface IGlossaryBuildResponse {
    id: number,
    state: string
}
