import {PretranslateMode} from "../model/PretranslateMode";
import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError} from "../globals/ApiGlobals";
import {ConcordanceSearchResult} from "../model/ConcordanceSearch";
import {List} from "immutable";
import {
    IConcordanceSearchResponse,
    toConcordanceSearchResultModel
} from "./model/TranslationMemoryApiModel";
import {Page} from "../model/Page";
import TranslationMemory from "../model/TranslationMemory";

export function pretranslate(projectId: number,
                             pretranslateMode: PretranslateMode,
                             translationMemory: TranslationMemory,
                             workflowStepId: number | null,
                             minimalWordsAmount: number | null,
                             catFileIds: List<number>) {
    const url = buildUrl(endpoint, {}, {
        projectId: projectId,
        pretranslateMode: pretranslateMode.mode,
        translationMemoryId: translationMemory.id,
        workflowStepId: workflowStepId,
        minimalWordsAmount: minimalWordsAmount,
        catFileIds: catFileIds.toArray()
    });
    axios
        .post(url)
        .catch(dispatchError);
}

export function executeConcordanceSearch(valueToFind: string,
                                         concordanceSearchType: string,
                                         sourceLanguage: string,
                                         targetLanguage: string,
                                         translationMemories: List<TranslationMemory>) {
    return axios
        .post(endpointDirect + "/concordance-search", {
            valueToFind: valueToFind,
            concordanceSearchType: concordanceSearchType,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage,
            translationMemoryIds: translationMemories.map(tm => tm.id).toArray()
        })
        .then((response: AxiosResponse<IConcordanceSearchResponse[]>) => {
            const list = response
                .data
                .map(value => toConcordanceSearchResultModel(value));
            return new Page<ConcordanceSearchResult>().setList(List(list));
        })
        .catch(dispatchFetchPageError<ConcordanceSearchResult>);
}

const endpoint = "/api/projects/pretranslate";
const endpointDirect = "/api/pretranslate/pretranslate";
