import {CatFile, FileState, TypeOfCatFile} from "../../model/CatFile";
import {IStatisticResponse, toStatisticModel} from "./StatisticApiModel";
import {List} from "immutable";
import {Build, BuildState} from "../../model/Build";
import {IProjectResponse} from "./ProjectApiModel";
import {Language} from "../../model/Language";

export function toCatFileModels(response: ICatFileResponse, targetLanguages: List<Language>) {
    if (targetLanguages.isEmpty()) {
        return [new CatFile({
            id: response.id,
            name: response.name,
            projectId: response.projectId,
            type: TypeOfCatFile[response.type as keyof typeof TypeOfCatFile],
            state: FileState[response.state as keyof typeof FileState],
            path: response.path
        })];

    }

    const catFiles: CatFile[] = [];
    targetLanguages.forEach(language => {
        const statistic = List(response
            .statistic
            .map(s => toStatisticModel(s))
            .filter(value => value.language.id === language.id)); // TODO: compare languages by equals

        if (statistic.isEmpty())
            return;

        catFiles.push(new CatFile({
            id: response.id,
            name: response.name,
            projectId: response.projectId,
            type: TypeOfCatFile[response.type as keyof typeof TypeOfCatFile],
            state: FileState[response.state as keyof typeof FileState],
            language: language,
            statistic: statistic,
            path: response.path
        }));
    });
    return catFiles;
}

export function toCatFileBuildModel(response: ICatFileBuildResponse) {
    return new Build({
        id: response.id,
        state: BuildState[response.state as keyof typeof BuildState],
    })
}

export interface ICatFileResponse {
    id: number,
    name: string,
    projectId: number,
    project: IProjectResponse,
    type: string,
    state: string,
    statistic: IStatisticResponse[],
    path: string
}

export interface IRequestUploadFile {
    projectId: number,
    file: File,
    fileName: string,
    fileType: TypeOfCatFile
}

export interface ICatFileBuildResponse {
    id: number,
    state: string
}
