import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import React, {useEffect, useState} from "react";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import {List} from "immutable";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import {setIsOpenUpdateTranslationsAction} from "../../../flux/project/editor/ProjectEditorActions";
import ProjectFilesTranslationsUploading from "./ProjectFilesTranslationsUploading";
import {updateProjectFilesTranslationsForLanguageAction} from "../../../flux/project/page/ProjectPageActions";
import {Language} from "../../../model/Language";

export default function ProjectFilesTranslationsEditor() {

    const project = projectPageStore.getState().project;
    const [files, setFiles] = useState<List<File>>(List<File>());
    const [updateTranslationsOpen,
        setUpdateTranslationsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setUpdateTranslationsOpen(state.updateTranslationsOpen);
            setFiles(state.files);
        });
        return () => projectEditorListener.remove();
    });

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <Dialog open={updateTranslationsOpen} fullWidth>
            <DialogTitle>Update translations</DialogTitle>
            <DialogContent>
                <DialogContentText>Target language: </DialogContentText>
                <Select
                    labelId="translation-files-language-select-label"
                    id="translation-files-language-select"
                    value={selectedLanguage}
                    label="Language"
                    onChange={handleChange}
                >
                    {project.targets.map((language, index) => (
                        <MenuItem key={index} value={language.id}>
                            {language.toString()}
                        </MenuItem>
                    ))}
                </Select>
                <DialogContentText>Only properties type of files supported.</DialogContentText>
                <ProjectFilesTranslationsUploading/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpenUpdateTranslationsAction(false)}>Cancel</Button>
                <Button disabled={files.size === 0} onClick={() => handleSubmit(
                    files,
                    project.id,
                    selectedLanguage)
                }>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function handleSubmit(files: List<File>, id: number, language: string) {
    updateProjectFilesTranslationsForLanguageAction(files, id, Language.fromSupported(language));
    setIsOpenUpdateTranslationsAction(false);
}