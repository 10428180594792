import {Map, Record} from "immutable";
import {Segment} from "../../../model/Segment";
import {ReduceStore} from "flux/utils";
import {SegmentListPayload, SegmentListPayloadAction} from "./SegmentListPayload";
import {dispatcher, Payload} from "../../Dispatcher";
import {Page} from "../../../model/Page";
import SegmentListFilter from "./SegmentListFilter";
import {SelectionModel} from "../../../model/SelectionModel";
import SegmentListLongTasks from "./SegmentListLongTasks";

interface ISegmentListState {
    items: Map<number, Segment>,
    totalsItems: number,
    position: number,
    page: Page<Segment>,
    selection: SelectionModel,
    filter: SegmentListFilter,
    longTasks: SegmentListLongTasks,
    withControls: boolean,
    projectUrl: string | null,
    lastClickedPosition: number | null,
    clearTargetAlertOpen: boolean
}

const SegmentListStateFactory = Record<ISegmentListState>({
    items: Map(),
    totalsItems: 0,
    position: 0,
    page: new Page<Segment>(),
    filter: new SegmentListFilter(),
    selection: new SelectionModel(),
    longTasks: new SegmentListLongTasks(),
    withControls: false,
    projectUrl: null,
    lastClickedPosition: null,
    clearTargetAlertOpen: false
});

export class SegmentListState extends SegmentListStateFactory {
}

export class SegmentListStore extends ReduceStore<SegmentListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): SegmentListState {
        return new SegmentListState();
    }

    reduce(state: SegmentListState, payload: SegmentListPayload): SegmentListState {
        switch (payload.action) {
            case SegmentListPayloadAction.SetSegmentListItems:
                return state.set("items", payload.items);
            case SegmentListPayloadAction.SetSegmentListTotalItems:
                return state
                    .set("filter", payload.filter)
                    .set("items", Map())
                    .set("totalsItems", payload.totalsItems)
                    .set("lastClickedPosition", null)
                    .set("selection", new SelectionModel());
            case SegmentListPayloadAction.SetSegmentListPosition:
                return state.set("position", payload.position);
            case SegmentListPayloadAction.SetIsSegmentSaving:
                return state.set("longTasks", state.longTasks.setIsSegmentSaving(payload.isSegmentSaving));
            case SegmentListPayloadAction.SetIsUpdatingSegments:
                return state.set("longTasks", state.longTasks.setIsUpdatingSegments(payload.isUpdating));
            case SegmentListPayloadAction.SetProjectUrl:
                return state.set("projectUrl", payload.projectUrl);
            case SegmentListPayloadAction.SelectSegment:
                return state.set("selection", state.selection.select(payload.id));
            case SegmentListPayloadAction.DeselectSegment:
                return state.set("selection", state.selection.deselect(payload.id));
            case SegmentListPayloadAction.ToggleSelectAll:
                return state.set("selection", state.selection.toggleSelectAll());
            case SegmentListPayloadAction.ToggleWithControls:
                return state.set("withControls", !state.get("withControls"));
            case SegmentListPayloadAction.SetLastClickedSegment:
                return state.set("lastClickedPosition", payload.position);
            case SegmentListPayloadAction.ToggleClearTargetAlert:
                return state.set("clearTargetAlertOpen", !state.get("clearTargetAlertOpen"));
            default:
                return state;
        }
    }
}

const segmentListStore = new SegmentListStore();
export default segmentListStore;
