import {styled} from "@mui/material/styles";
import {Container, ListItemIcon} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {
    List as MuiList,
    ListItem,
    ListItemText,
    Collapse
} from '@mui/material';
import {List as ImmutableList} from 'immutable';
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Segment} from "../../../../model/Segment";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import ErrorModel from "../../../../model/ErrorModel";

export const NoPaddingContainer = styled(Container)({
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

export default function ErrorView() {
    const [errors, setErrors] = useState<{ [key: string]: ErrorModel[] }>({});
    const [openErrors, setOpenErrors] = useState<{ [key: string]: boolean }>({});
    const initialSegmentState = segmentStore.getState();

    const [currentSegment, setCurrentSegment]
        = useState<Segment | null>(initialSegmentState.segment);

    useEffect(() => {
        const segmentListener = segmentStore.addListener(() => {
            const state = segmentStore.getState();
            setCurrentSegment(state.segment);
            if (currentSegment && currentSegment?.translation && currentSegment?.translation?.markupErrorModels) {
                const groupedErrors = groupErrorsByErrorType(currentSegment?.translation?.markupErrorModels);
                setErrors(groupedErrors);
            }
        });
        return () => {
            segmentListener.remove();
        }
    });

    return (
        <NoPaddingContainer sx={{
            overflowY: 'auto',
            height: '100%',
            maxHeight: '100%'
        }}>
            <Box sx={{width: 400, padding: 2}}>
                <MuiList component="nav" aria-labelledby="nested-list-subheader">
                    {Object.keys(errors).map((key) =>
                        drawError(setOpenErrors, openErrors, key, errors)
                    )}
                </MuiList>
            </Box>
        </NoPaddingContainer>
    );
}

function groupErrorsByErrorType(markupErrors: ImmutableList<ErrorModel>): { [key: string]: ErrorModel[] } {
    return markupErrors.reduce<{ [key: string]: ErrorModel[] }>((acc, curr) => {
        if (!acc[curr.errorType])
            acc[curr.errorType] = [];

        acc[curr.errorType].push(curr);

        return acc;
    }, {});
}

function drawError(setOpenErrors: React.Dispatch<React.SetStateAction<any>>, openErrors: any, key: string, errors:  { [key: string]:ErrorModel[]}) {
    return (
        <>
            <ListItem component={"button"}
                onClick={() => setOpenErrors((prev: { [x: string]: any; }) => ({...prev, [key]: !prev[key]}))}
                sx={{padding: '8px 16px', borderBottom: '1px solid #e0e0e0'}}
            >
                <ListItemIcon sx={{minWidth: '30px'}}>
                    {openErrors[key] ? <ExpandLess/> : <ExpandMore/>}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body1">
                            {key} ({errors[key].length})
                        </Typography>
                    }
                />
            </ListItem>

            <Collapse in={openErrors[key]} timeout="auto" unmountOnExit>
                <MuiList component="div" disablePadding>
                    {errors[key].map((errorItem, index) => (
                        <ListItem key={`${key}-${index}`}>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{paddingLeft: '40px'}}>
                                        {errorItem.error}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </MuiList>
            </Collapse>
        </>
    );
}