import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import ProjectFilesUploading from "./ProjectFilesUploading";
import {List} from "immutable";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import {uploadFilesAction} from "../../../flux/project/page/ProjectPageActions";
import {setIsOpenUploadFilesAction} from "../../../flux/project/editor/ProjectEditorActions";
import Project from "../../../model/Project";

export default function ProjectFilesEditor() {

    const project = projectPageStore.getState().project;
    const [files, setFiles] = useState<List<File>>(List<File>());
    const [uploadFilesOpen, setUploadFilesOpen] = useState(false);
    const [multiLanguage, setMultiLanguage] = useState(false);

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setUploadFilesOpen(state.uploadFilesOpen);
            setFiles(state.files);
            setMultiLanguage(state.multiLanguage);
        });
        return () => projectEditorListener.remove();
    });

    return (
        <Dialog open={uploadFilesOpen} fullWidth>
            <DialogTitle>Upload files</DialogTitle>
            <DialogContent>
                <ProjectFilesUploading/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpenUploadFilesAction(false)}>Cancel</Button>
                <Button disabled={files.size === 0} onClick={() => handleSubmit(project, files, multiLanguage)}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function handleSubmit(project: Project, files: List<File>, multiLanguage: boolean) {
    uploadFilesAction(files, project.id, multiLanguage);
    setIsOpenUploadFilesAction(false);
}