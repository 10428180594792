import Immutable from "immutable";
import {ISearchResult} from "./ISearchResult";
import {createSlate} from "../utils/slate/SlateUtils";

interface IGlossaryPair extends ISearchResult {
    glossary: string,
    comment: string
}

const GlossaryPairSearchResultRecord = Immutable.Record<IGlossaryPair>({
    source: "",
    target: "",
    sourceEditor:createSlate(""),
    targetEditor:createSlate(""),
    glossary: "",
    comment: ""
});

export default class GlossaryPairSearchResult extends GlossaryPairSearchResultRecord {
}