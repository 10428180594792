import Immutable from "immutable";
import {ISearchResult} from "./ISearchResult";
import {createSlate} from "../utils/slate/SlateUtils";

interface IConcordanceSearchResult extends ISearchResult {
    translationMemoryName: string
}

const ConcordanceSearchResultRecord = Immutable.Record<IConcordanceSearchResult>({
    source: '',
    target: '',
    sourceEditor: createSlate(),
    targetEditor: createSlate(),
    translationMemoryName: ''
});

export class ConcordanceSearchResult extends ConcordanceSearchResultRecord {
}