import {createEditor, Editor} from "slate";
import {Editable, ReactEditor, withReact} from "slate-react";
import React from "react";
import {styled} from "@mui/material/styles";
import {withHistory} from "slate-history";
import {SlateEntityType} from "./SlateModels";
import slateSerializer from "./SlateSerializer";

const InlineBugFixSpan = styled("span")({
    fontSize: 0
});

export const CustomEditable = styled(Editable)({
    textAlign: 'start',
    wordBreak: "break-word"
});

export const CustomEditableSmallText = styled(Editable)({
    textAlign: 'start',
    fontSize: 12
});

export const InlineChromiumBugfix = () => (
    <InlineBugFixSpan contentEditable={false}>
        {String.fromCodePoint(160) /* Non-breaking space */}
    </InlineBugFixSpan>
);


export function editorText(editor: ReactEditor) {
    return slateSerializer.serialize(editor.children);
}

export function createSlateWithHistory(text=""){
    const editor =  withInlines(withHistory(withReact(createEditor())));
    editor.children = slateSerializer.deserialize(text).children;
    return editor
}

export function createSlate(text=""){
    const editor = withInlines(withReact(createEditor()));
    editor.children = slateSerializer.deserialize(text).children;
    return editor
}

export function withInlines(editor: Editor): Editor {
    const {isElementReadOnly, isInline, isSelectable} = editor;

    editor.isInline = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isInline(element);
    editor.isElementReadOnly = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isElementReadOnly(element);
    editor.isSelectable = (element) => element.type !== SlateEntityType.Placeholder
        && element.type !== SlateEntityType.EscapedText
        && isSelectable(element);
    return editor;
}

export function escapePlaceholderContent(content: string) {
    return content.replaceAll('\n', '\\n');
}
