import Box from "@mui/material/Box";
import {Grid2} from "@mui/material";
import {selectGlossaryPairAction, selectTMSuggestAction} from "../../../../flux/segment/editor/SegmentEditorActions";
import React from "react";
import {BreakWordGrid, CustomChip, SearchResultGrid, SmallText} from "../../../../globals/CommonComponents";
import {SearchResultPrefix} from "./ExistingTranslationsView";
import TranslationMemorySearchResult from "../../../../model/TranslationMemorySearchResult";
import {CustomEditableSmallText} from "../../../../utils/slate/SlateUtils";
import {Slate} from "slate-react";
import {RenderedSourceElement} from "../segments/rendering/RenderedSourceElement";

type TMSearchResultProps = {
    index: number,
    isSelected: boolean,
    searchResult: TranslationMemorySearchResult,
    onClick: (index: number) => void,
    onDoubleClick: () => void
}

export default function TMSearchResult(props: TMSearchResultProps) {
    const searchResultId = SearchResultPrefix + props.index;
    const sourceEditor = props.searchResult.sourceEditor;
    const targetEditor = props.searchResult.targetEditor;

    return <Box sx={{padding: 1}} key={searchResultId}>
        <SearchResultGrid container alignItems={"flex-start"} justifyContent={"flex-start"}
                          isSelected={props.isSelected}
                          wrap="nowrap"
                          onClick={() => {
                              props.onClick(props.index);
                              selectGlossaryPairAction(null);
                              selectTMSuggestAction(props.searchResult)
                          }}
                          onDoubleClick={props.onDoubleClick}
                          id={searchResultId} tabIndex={0}>
            <Grid2 size={1} textAlign={"center"}>
                <SmallText>{props.index + 1}</SmallText>
            </Grid2>
            <BreakWordGrid size={4}>
                <Slate editor={sourceEditor}
                       initialValue={sourceEditor.children}>
                    <CustomEditableSmallText readOnly={true} renderElement={renderProps =>
                        <RenderedSourceElement children={renderProps.children} element={renderProps.element}
                                               attributes={renderProps.attributes} onPlaceholder={() => {
                        }}
                                               id={props.index}/>}/>
                </Slate>
            </BreakWordGrid>
            <Grid2 size={2} textAlign={"center"}  sx={{ minWidth: '45px'}}>
                <CustomChip label={props.searchResult.scoring + "%"} size={"small"} sx={{ minWidth: '45px'}}/>
            </Grid2>
            <BreakWordGrid size={4}>
                <Slate editor={targetEditor}
                       initialValue={targetEditor.children}>
                    <CustomEditableSmallText readOnly={true} renderElement={renderProps =>
                        <RenderedSourceElement children={renderProps.children} element={renderProps.element}
                                               attributes={renderProps.attributes} onPlaceholder={() => {
                        }}
                                               id={props.index}/>}/>
                </Slate>
            </BreakWordGrid>
        </SearchResultGrid>
    </Box>;
}