import {RenderLeafProps} from "slate-react";
import React from "react";
import {styled} from "@mui/material/styles";

// Slate bugfixes from official example: https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx
const BugFixSpan = styled("span")({
    paddingLeft: '0.1px'
});

export function RenderedText(props: RenderLeafProps) {
    const {attributes, children, leaf} = props;
    if (leaf.decoration === "mark")
        return <mark {...attributes}>{children}</mark>
    return leaf.text === ''
        ? <BugFixSpan {...attributes}>{children}</BugFixSpan>
        : <span {...attributes}>{children}</span>
}