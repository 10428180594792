import {GridActionsCellItem, GridRenderCellParams} from "@mui/x-data-grid";
import {Stack, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {permissionsListActions} from "../../flux/user-permissions/list/PermissionsSelectActions";
import {Permission} from "../../model/Permission";
import {AvailableResourceType} from "../../model/AvailableResources";
import PrivateComponent from "../PrivateComponent";

type PermissionActionsProperties = {
    renderCellParams: GridRenderCellParams<Permission, Permission>,
}

export default function PermissionActions(props: PermissionActionsProperties) {
    return (
        <Stack direction={"row"}>
            <PrivateComponent resource={AvailableResourceType.Permissions} deleteAllow={true}>
                <GridActionsCellItem label={"Delete"} icon={<Tooltip title={"Delete"} arrow><DeleteIcon/></Tooltip>}
                                     onClick={() => permissionsListActions.delete(props.renderCellParams.row)}/>
            </PrivateComponent>
        </Stack>
    );
}