import Immutable from "immutable";
import {dispatcher} from "../../Dispatcher";
import {
    setIsUpdateTranslationsOpen,
    setIsUploadFilesOpen,
    setMultiLanguageMode,
    setProjectFiles,
    setStep
} from "./ProjectEditorPayload";
import {initProjectAction} from "../page/ProjectPageActions";

export function setProjectFilesAction(files: Immutable.List<File>) {
    dispatcher.dispatch(setProjectFiles(files));
}

export function setStepAction(step: number) {
    dispatcher.dispatch(setStep(step));
}

export function editNewProjectAction(){
    initProjectAction();
    dispatcher.dispatch(setIsUploadFilesOpen(true));
}

export function setIsOpenUploadFilesAction(open: boolean) {
    dispatcher.dispatch(setIsUploadFilesOpen(open));
}

export function setIsOpenUpdateTranslationsAction(open: boolean) {
    dispatcher.dispatch(setIsUpdateTranslationsOpen(open));
}

export function setMultiLanguageModeAction(multiLanguage: boolean) {
    dispatcher.dispatch(setMultiLanguageMode(multiLanguage));
}