import React, {useState} from "react";
import {Container, Stack, Tabs} from "@mui/material";
import {SimpleTab} from "../../../globals/CommonComponents";
import ConcordanceSearchView from "./concordance-search/ConcordanceSearchView";
import CatInfoView from "./cat-info/CatInfoView";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import ErrorView from "./warnings/ErrorView";
import {styled} from "@mui/material/styles";

enum TabName {
    ConcordanceSearch = "ConcordanceSearch",
    CatInfo = "CatInfo",
    Error = "Error"
}

const TabContainer = styled(Container)({
    margin:0,
    maxWidth: '500px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
});

export default function BottomRightFrame() {
    const [currentTab, setCurrentTab]
        = useState<TabName>(TabName.CatInfo);

    const handleTabChanged = (value: TabName) => {
        setCurrentTab(value);
    }

    function drawTab() {
        switch ( currentTab ) {
            case TabName.CatInfo:
                return <CatInfoView/>;
            case TabName.ConcordanceSearch:
                return <ConcordanceSearchView/>;
            case TabName.Error:
                return <ErrorView/>;
        }
    }

    return (
        <TabContainer maxWidth="sm">
            <Stack sx={{height: '100%',padding:0,maxWidth: '500px'}}>
                <Container disableGutters>
                    <Tabs value={currentTab} onChange={(_event, value) =>
                        handleTabChanged(value)}>
                        <SimpleTab label={"INFO"} value={TabName.CatInfo}/>
                        <SimpleTab label={"CONCORDANCE"} value={TabName.ConcordanceSearch}/>
                        <SimpleTab value={TabName.Error}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ErrorOutline  color="error" style={{ fontSize: 20, marginRight: 8 }} />
                                    <span>{"WARNINGS"}</span>
                                </div>
                            }
                        />
                    </Tabs>
                </Container>
                {drawTab()}
            </Stack>
        </TabContainer>
    )
}