import React, {useEffect, useState} from "react";
import {Badge, Chip, IconButton, Stack, styled, Tooltip} from "@mui/material";
import Project from "../../../model/Project";
import {User} from "../../../model/User";
import SegmentListFilter, {ISegmentListFilter} from "../../../flux/segment/list/SegmentListFilter";
import Immutable from "immutable";
import {NavigateFunction, useNavigate} from "react-router-dom";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import segmentListStore from "../../../flux/segment/list/SegmentListStore";
import usersListStore from "../../../flux/users/list/UsersListStore";
import {OverflowContainer} from "../../../globals/CommonComponents";
import Box from "@mui/material/Box";
import {refreshSegmentListPageAction} from "../../../flux/segment/list/SegmentListActions";
import {getAuthorOptions} from "../../../utils/UserUtils";
import {updatePage} from "../../../utils/FilterUtils";

type TopRightFrameProps = {
    isFilterSectionOpen: boolean,
    openFilterSection: (isHidden: boolean) => void;
}

const CustomChip = styled(Chip)({
    maxWidth: 280,
    maxHeight: 35,
    minHeight: 30,
    radius: 100
})

export default function TopRightFrame(props: TopRightFrameProps) {
    const initialProjectState = projectPageStore.getState();
    const initialFilterState = segmentListStore.getState().filter;
    const initialUsersState = usersListStore.getState();

    const [totalItems, setTotalItems] = useState(0);
    const [project, setProject] = useState(initialProjectState.project);
    const [filter, setFilter] = useState(initialFilterState);
    const [users, setUsers] = useState(getAuthorOptions(
        initialUsersState.administrators?.list,
        initialUsersState.usersWithPermissions?.list));
    const [selectedWorkflowSteps, setSelectedWorkflowSteps] =
        useState<Immutable.List<number>>(Immutable.List());

    const navigate = useNavigate();

    const toggleFilter = () => props.openFilterSection(!props.isFilterSectionOpen);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const workflowIds = state.filter.workflowIds;
            setFilter(state.filter);
            setSelectedWorkflowSteps(workflowIds ? Immutable.List(workflowIds) : Immutable.List());
            setTotalItems(state.totalsItems);
        });

        const usersListListener = usersListStore.addListener(() => {
            const state = usersListStore.getState();
            setUsers(getAuthorOptions(state.administrators?.list, state.usersWithPermissions?.list))
        });

        return () => {
            segmentListListener.remove();
            projectPageListener.remove();
            usersListListener.remove();
        }
    }, []);

    return (
        <OverflowContainer maxWidth="sm"
                           sx={{
                               minHeight: '40px',
                               margin: 0,
                               maxWidth: '500px',
                               borderTop: 'none',
                               borderBottom: '1.2px solid #d6d6d6'
                           }}>
            <Box
                display="flex"
                flexDirection="column"
                pt={1}
                pl={1.5}
            >
                {/* Chip section */}
                <Box pb={1} sx={{ maxHeight: '100px', overflowY: 'auto' }}>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                        {drawSelectedFilters(project, users, filter, selectedWorkflowSteps, navigate)}
                    </Box>
                </Box>

                {/* Button section */}
                <Box display="flex"
                     flexDirection="column"
                     justifyContent="flex-end"
                >
                    <Stack direction="row" spacing={1}>
                        <Tooltip title={props.isFilterSectionOpen ? "Show Filters" : "Hide Filters"}>
                            <IconButton onClick={toggleFilter}>
                                <img src={props.isFilterSectionOpen ? "/HideFilters.svg" : "/ShowFilter.svg"}
                                     alt={props.isFilterSectionOpen ? "HideFilters" : "ShowFilters"}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Refresh">
                            <IconButton
                                onClick={() => {
                                    refreshSegmentListPageAction();
                                    updatePage(navigate, filter);
                                }}>
                                <Badge badgeContent={totalItems} max={1000000} color="primary">
                                    <img src={"/refresh.svg"} alt={"refresh"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
            </Box>
        </OverflowContainer>
    );
}


function drawSelectedFilters(project: Project,
                             users: User[],
                             filter: SegmentListFilter,
                             selectedWorkflowSteps: Immutable.List<number>,
                             navigate: NavigateFunction) {
    const filters: React.JSX.Element[] = [];
    if (filter.source) {
        const title = filter.sourceMatchCase ? "Source (match-case)" : "Source";
        filters.push(drawSelectedFilter(title + ": " + filter.source, "source", "source", filter, navigate));
    }

    if (filter.target) {
        const title = filter.targetMatchCase ? "Target (match-case)" : "Target";
        filters.push(drawSelectedFilter(title + ": " + filter.target, "target", "target", filter, navigate));

    }

    if (filter.sourceId) {
        filters.push(drawSelectedFilter("Source ID: " + filter.sourceId, "sourceId", "sourceId", filter, navigate));
    }


    const selectedWorkflowStepsArray: string[] = [];
    selectedWorkflowSteps.forEach(workflowStepId => {
        const foundWorkflowStep = project.workflow.find(workflowStep => workflowStep.id === Number(workflowStepId));
        if (foundWorkflowStep)
            selectedWorkflowStepsArray.push(foundWorkflowStep.name);
    });
    if (selectedWorkflowStepsArray.length > 0) {
        const chipText = selectedWorkflowStepsArray.join(", ");
        filters.push(drawSelectedFilter(chipText, "workflowIds", "workflowIds", filter, navigate));
    }

    if (filter.withComments) {
        filters.push(drawSelectedFilter("With comments", "withComments", "withComments", filter, navigate));
    }
    if (filter.withoutComments) {
        filters.push(drawSelectedFilter("Without comments", "withoutComments", "withoutComments", filter, navigate));
    }
    if (filter.withLocked)
        filters.push(drawSelectedFilter("Locked segments", "withLocked", "withLocked", filter, navigate));

    if (filter.withUnlocked)
        filters.push(drawSelectedFilter("Unlocked segments", "withUnlocked", "withUnlocked", filter, navigate));

    const authorsFilter = drawAuthorsFilter(filter, users, navigate);
    if (authorsFilter)
        filters.push(authorsFilter);

    if (filter.from)
        filters.push(drawSelectedFilter("From: " + filter.from, "from", "from", filter, navigate));

    if (filter.to)
        filters.push(drawSelectedFilter("To: " + filter.to, "to", "to", filter, navigate));

    return filters;
}

function drawSelectedFilter(chipText: string,
                            filterName: keyof ISegmentListFilter,
                            key: string,
                            filter: SegmentListFilter,
                            navigate: NavigateFunction) {
    return <Tooltip title={chipText}>
        <CustomChip size={"small"} label={chipText}
                    onDelete={() => handleFilterDeleted(filterName, filter, navigate)}
                    key={key + "-filter-chip"}/>
    </Tooltip>;
}

function drawAuthorsFilter(filter: SegmentListFilter, users: User[], navigate: NavigateFunction) {
    if (!filter.authors)
        return;
    const selectedUsersArray: string[] = [];
    filter.authors.forEach(userId => {
        const foundUser = users.find(user => user.id === userId);
        if (foundUser)
            selectedUsersArray.push(foundUser.name);
    });
    if (selectedUsersArray.length > 0) {
        const chipText = "Users: " + selectedUsersArray.join(", ");
        return drawSelectedFilter(chipText, "authors", "authors", filter, navigate);
    }
    return null;
}

function handleFilterDeleted(name: keyof ISegmentListFilter, filter: SegmentListFilter, navigate: NavigateFunction) {
    updatePage(navigate, filter.set(name, null));
}
