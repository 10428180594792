import {User} from "../model/User";
import {List} from "immutable"

export function getAuthorOptions(administrators: List<User> | undefined,
                                 usersWithPermissions: List<User> | undefined) {
    const options: User[] = [];
    if (administrators)
        administrators.forEach(administrator => options.push(administrator));
    if (usersWithPermissions) {
        usersWithPermissions.forEach(user => {
            const foundUser = options.find(u => u.id === user.id);
            if (!foundUser)
                options.push(user);
        });
    }
    return options;
}