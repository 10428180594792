// React uses unique keys.
// Sequence should be used if hard to generate unique key
// If necessary make it thread safe
export class IdSequence {
    static Instance = new IdSequence();

    private _value = 0;

    next() {
        return this._value++
    }
}