import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    getGridStringOperators,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridColDef,
    GridColumnHeaderParams,
    GridRowParams
} from "@mui/x-data-grid";
import UploadIcon from "@mui/icons-material/Upload";
import UploadedTranslationMemoryEditor from "../../../translation-memory/UploadedTranslationMemoryEditor";
import {
    setEditedTranslationMemoryAction
} from "../../../../flux/translation-memory/editor/TranslationMemoryEditorActions";
import TranslationMemory, {
    isPending,
    ProjectTranslationMemory,
    TranslationMemoryGroup
} from "../../../../model/TranslationMemory";
import ListView from "../../../common/ListView";
import SelectDialog from "../../../common/SelectDialog";
import {saveTranslationMemoriesAction} from "../../../../flux/project/page/ProjectPageActions";
import Project from "../../../../model/Project";
import {List, Set} from "immutable";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {
    projectTranslationMemoryListActions,
} from "../../../../flux/translation-memory/list/TranslationMemoryListActions";
import {
    translationMemorySelectActions
} from "../../../../flux/translation-memory/select/TranslationMemorySelectActions";
import TranslationMemoryFilter from "../../../../flux/translation-memory/TranslationMemoryFilter";
import {GridRowStringOperatorsDefault, GridRowStringOperatorsWithAnyOf} from "../../../../globals/Constants";
import {defaultColumns} from "../../../../utils/ListViewUtils";
import {useProject} from "../../ProjectView";
import {useSearchParams} from "react-router-dom";
import {getProjectTranslationMemoriesPath} from "../../../../routes/project/tabs/ProjectTranslationMemoriesRoute";
import {
    translationMemoryGroupBuildActions
} from "../../../../flux/translation-memory/build/TranslationMemoryBuildActions";
import {BuildState} from "../../../../model/Build";
import ProjectTranslationMemoryState from "./ProjectTranslationMemoryState";
import {useInterval} from "../../../../globals/CustomHooks";

export default function ProjectTranslationMemories() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();
    const [build, setBuild] = useState(!translationMemoryGroupBuildActions.state.building.isEmpty());
    const [selected, setSelected] = useState(projectTranslationMemoryListActions.state.selected);
    const [filter, setFilter] = useState<TranslationMemoryFilter>(TranslationMemoryFilter.fromSearchParams(
        project.id,
        searchParams));

    const [openEditor, setOpenEditor] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);

    useEffect(() => {
        const translationMemoryBuildListener = translationMemoryGroupBuildActions.store
            .addListener(() => {
                setBuild(!translationMemoryGroupBuildActions.state.building.isEmpty())
            });

        const translationMemoryListListener = projectTranslationMemoryListActions.store
            .addListener(() => {
                const state = projectTranslationMemoryListActions.state;
                setSelected(state.selected);
            });

        return () => {
            translationMemoryBuildListener.remove();
            translationMemoryListListener.remove();
        }
    }, []);

    useEffect(() => {
        const filter = TranslationMemoryFilter.fromSearchParams(project.id, searchParams);
        setFilter(filter);
        projectTranslationMemoryListActions.fetch(filter);
    }, [project]);

    useInterval(() => {
        projectTranslationMemoryListActions.refresh(false);
    }, 2000);

    return (
        <Stack sx={{height: '100%'}}>
            <UploadedTranslationMemoryEditor open={openEditor}
                                             onClose={(tm: TranslationMemory | null) =>
                                                 handleClose(setOpenEditor, project, tm)}/>
            <SelectDialog open={openSelect}
                          actions={translationMemorySelectActions}
                          onClose={() => setOpenSelect(false)}
                          onSelect={(selected: Set<TranslationMemory>) => {
                              const tms = selected.toList().concat(project.translationMemories);
                              saveTranslationMemoriesAction(project.id, tms, project.writableTm);
                          }}
                          columns={defaultColumns(true)}
                          navigateProps={null}
                          initialFilter={new TranslationMemoryFilter()}/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => {
                        translationMemorySelectActions.fetch(new TranslationMemoryFilter());
                        setOpenSelect(true);
                    }}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditedTranslationMemoryAction(new TranslationMemory());
                        setOpenEditor(true);
                    }}>
                        <UploadIcon/>
                    </IconButton>
                    <IconButton onClick={() => downloadTm(selected)} disabled={build || selected.isEmpty()}>
                        {build && <CircularProgress sx={{position: 'absolute'}}/>}
                        <DownloadIcon/>
                    </IconButton>
                    <IconButton onClick={() => removeTm(project, selected)}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                {filter &&
                    <ListView actions={projectTranslationMemoryListActions} columns={columns(project)}
                              navigateProps={{navigator: getProjectTranslationMemoriesPath}} initialFilter={filter}/>}
            </Stack>
        </Stack>
    );
}

function columns(project: Project): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            valueGetter: (params: GridValueGetterParams<ProjectTranslationMemory>) =>
                params.row.translationMemory.name,
            renderCell: (params: GridRenderCellParams<ProjectTranslationMemory>) => {
                return params.row.translationMemory.name
                    ? params.row.translationMemory.name
                    : <Typography color={"red"} fontSize={14}>Removed</Typography>
            },
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'sourceLanguage',
            sortable: false,
            headerName: 'Source',
            valueGetter: (params: GridValueGetterParams<ProjectTranslationMemory>) =>
                params.row.translationMemory.sourceLanguage,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'targetLanguage',
            sortable: false,
            headerName: 'Target',
            valueGetter: (params: GridValueGetterParams<ProjectTranslationMemory>) => {
                return params.row.translationMemory.targetLanguages.join(",")
            },
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsWithAnyOf.includes(operator.value))
        },
        {
            field: 'update',
            headerName: 'Update',
            type: 'actions',
            getActions: (params: GridRowParams<ProjectTranslationMemory>) => [
                generateWriteIcon(params.row, project)
            ],
            renderHeader: (_params: GridColumnHeaderParams) =>
                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                    <Typography fontWeight={500} fontSize={14}>Update</Typography>
                    <Tooltip title={"When activated, the 'Update' checkbox initiates a background process to " +
                        "transfer completed translations from the project into the translation memory. This may take " +
                        "some time to complete."}>
                        <IconButton size={"small"}>
                            <HelpOutlineIcon fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>
                </Stack>
        },
        {
            field: 'actions',
            headerName: 'Disable',
            type: 'actions',
            getActions: (params: GridRowParams<TranslationMemory>) => [
                <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                     onClick={() => removeTm(project, Set.of(params.row))}/>
            ]
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            renderCell: (params: GridRenderCellParams<ProjectTranslationMemory>) =>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <ProjectTranslationMemoryState row={params.row}/>
                    {params.row.state === BuildState.Pending && <CircularProgress size={14}/>}
                </Stack>,
            filterable: false,
            sortable: false
        }
    ];
}

function removeTm(project: Project, toRemove: Set<TranslationMemory>) {
    const updated = project
        .translationMemories
        .filter(projectTm => !toRemove.find(removeTm => removeTm.id === projectTm.id));
    saveTranslationMemoriesAction(project.id, updated, project.writableTm);
}

function downloadTm(selected: Set<TranslationMemory>) {
    translationMemoryGroupBuildActions.build(new TranslationMemoryGroup(selected));
}

function generateWriteIcon(tm: ProjectTranslationMemory, project: Project)
    : React.ReactElement<GridActionsCellItemProps> {
    if (isPending(tm.translationMemory))
        return <CircularProgress size={20}/>
    else if (project.writableTm === tm.id)
        return (
            <GridActionsCellItem label={'Update'} icon={<CheckBoxIcon/>} disabled={tm.translationMemory.name === null}
                                 onClick={() => saveTranslationMemoriesAction(
                                     project.id,
                                     project.translationMemories,
                                     null)}/>
        )
    else
        return (
            <GridActionsCellItem label={'Update'} icon={<CheckBoxOutlineBlankIcon/>}
                                 disabled={tm.translationMemory.name === null}
                                 onClick={() => saveTranslationMemoriesAction(
                                     project.id,
                                     project.translationMemories,
                                     tm.id)}/>
        )
}

function handleClose(setOpenEditor: (v: boolean) => void, project: Project, tm: TranslationMemory | null) {
    setOpenEditor(false);
    if (!tm)
        return;
    const foundTm = project.translationMemories.find(translationMemory =>
        translationMemory.id === tm.id);
    if (foundTm)
        return;
    const tms = List(project.translationMemories);
    tms.push(tm);
    saveTranslationMemoriesAction(project.id, tms, project.writableTm);
}
